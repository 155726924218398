
import web from '@/api/web/co.web'

const CntTypes = web.comm.ContentTypes

const PullTypes = [
  CntTypes.IMAGE,
  CntTypes.VIDEO,
  CntTypes.ARTICLE,
  CntTypes.POUND
]

const Names = {
  ALL: 'all',
  DYNAMIC: 'dynamic',
  ATTENTION: 'attention',
  COLLECTION: 'collection',
  LIKE: 'like'
}

const CatAll = {
  name: Names.ALL,
  title: '全部',
  type: PullTypes.join('|'),
  params: null,
  items: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
}

const CatDynamic = {
  name: Names.DYNAMIC,
  title: '我的动态',
  type: '',
  params: null,
  items: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
  empty: '当前还没有我的动态'
}

const CatAttention = {
  name: Names.ATTENTION,
  title: '我的关注',
  type: '',
  params: null,
  items: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
  empty: '当前还没有我的关注'
}

const CatCollection = {
  name: Names.COLLECTION,
  title: '我的收藏',
  type: '',
  params: null,
  items: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
  empty: '当前还没有我的收藏'
}

const CatLike = {
  name: Names.LIKE,
  title: '我的点赞',
  type: '',
  params: null,
  items: [],
  pagination: {
    current: 1,
    length: 0,
    totalVisible: 7
  },
  empty: '当前还没有我的点赞'
}

const Scopes = web.comm.Scopes
const CntActions = web.comm.ContentActions

const CardRapids = {
  [Scopes.PUBLIC]: {
    icon: 'mdi-eye-outline',
    text: '公开',
    value: Scopes.PUBLIC,
  },
  [Scopes.PRIVATE]: {
    icon: 'mdi-eye-lock-outline',
    text: '仅自己可见',
    value: Scopes.PRIVATE,
  },
  [Scopes.FANS]: {
    icon: 'mdi-eye-check-outline',
    text: '粉丝可见',
    value: Scopes.FANS,
  },
  [Scopes.MUTUAL]: {
    icon: 'mdi-eye-plus-outline',
    text: '互关可见',
    value: Scopes.MUTUAL,
  },
  [CntActions.REMOVE]: {
    icon: 'mdi-trash-can-outline',
    text: '删除',
    value: CntActions.REMOVE,
  },
  [CntActions.ATTENTION_PLUS]: {
    icon: 'mdi-account-multiple-plus-outline',
    text: '加关注',
    value: CntActions.ATTENTION_PLUS,
  },
  [CntActions.ATTENTION_MINUS]: {
    icon: 'mdi-account-multiple-minus-outline',
    text: '取消关注',
    value: CntActions.ATTENTION_MINUS,
  },
  [CntActions.SHIELD]: {
    icon: 'mdi-eye-off-outline',
    text: '屏蔽此条',
    value: CntActions.SHIELD,
  }
}

const homePreset = {
  Names,
  Parts: {
    [Names.ALL]: CatAll,
    [Names.DYNAMIC]: CatDynamic,
    [Names.ATTENTION]: CatAttention,
    [Names.COLLECTION]: CatCollection,
    [Names.LIKE]: CatLike
  },
  CardRapids
}

export default homePreset
